/* src/styles/Footer.css */

.footer {
    background-color: #141718;
    color: #fff;
    text-align: center;
    padding: 20px;
}

.footer p {
    margin: 0;
}

.footer .MuiIconButton-root {
    color: #fff;
    /* Ensure icons match the footer text color */
}