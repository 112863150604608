/* src/pages/LandingPage.css */
.landing-page {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

.hero-section {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.features-section,
.testimonials-section,
.pricing-section,
.final-cta {
    padding: 50px;
    text-align: center;
}

.features {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.feature {
    flex: 1;
    padding: 20px;
}
@media (max-width: 768px) {
    .hero-section {
        height: auto;
        padding: 50px 0;
        text-align: center;
    }

    .features-section {
        padding: 20px;
    }

    .final-cta {
        padding: 20px;
    }
}
.cards-section {
    padding: 50px 0;
    background-color: #f4f4f4;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 40px;
}

.cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.pricing-section {
    padding: 4rem;
    background-color: #f7fafc;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2d3748;
    margin-bottom: 1rem;
}

.pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.pricing-card {
    width: 100%;
    max-width: 15rem;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
    /* justify-content: space-between; */
}

.pricing-card:hover {
    transform: scale(1.05);
}

.plan-name {
    font-size: 2.25rem;  
    font-weight: 600;
    color: #4a5568;
}

.plan-price {
    font-size: 1.75rem;
    font-weight: 900;
    color: #1f7a8c;
    /* margin: 1rem 0; */
}

.plan-features {
    list-style-type: decimal;
    padding: 2;
    /* margin-bottom: 1.7rem; */
    color: #0c0e11;
}

.plan-features li {
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 1.2rem;
}

.try-now-button {
    display: inline-block;
    padding: 0.5rem 1.5rem;
   margin-top: 30%;
    font-weight: 600;
    color: #fff;
    border-radius: 9999px;
    background-color: #1f7a8c;
    text-decoration: none;
    transition: background-color 0.3s;
}

.try-now-button:hover {
    background-color: #1f7a8c;
}
.hero-section {
    position: relative;
    overflow: hidden;
    /* Prevents overflow of the carousel */
    height: 400px;
    /* Set height of the hero section */
    display: flex;
    /* Center the content */
    align-items: center;
    /* Vertically center content */
    justify-content: center;
    /* Horizontally center content */
    background-color: #000;
    /* Optional: Add a background color */
    z-index: 1;
    /* Set z-index to ensure it appears above background elements */
}

/* Hero Section */
.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    text-align: center;
    background-position: center;
    position: relative;
    padding: 50px;
    background: #ffffff;
    text-align: center;

}

.hero-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.hero-title {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 40px;
    line-height: 1.5;
}

.cta-button {
    background: transparent;
    border: 2px solid #fff;
    color: #ffffff;
    padding: 12px 30px;
    font-size: 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
    background-color: #fff;
    color: #000;
}

/* Features Section */
.features-section {
    padding: 60px 20px;
    text-align: center;
}

.features-section h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.feature-card {
    background-color: #292929;
    padding: 40px;
    margin: 20px;
    border-radius: 10px;
    width: 300px;
    display: inline-block;
    vertical-align: top;
}

.feature-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.feature-description {
    font-size: 1rem;
    color: #2a2828;
}




.hero-content h2 {
    font-size: 2rem;
    /* Font size for the title */
    margin-top: 20px;
    /* Space above the title */
}

.typed-words {
    color: #776CFF;
    font-weight: bold;
}

/* Cursor Style */
.typed-cursor {
    display: inline-block;
    width: 8px;
    /* Width of the cursor */
    height: 1em;
    /* Match height to font size */
    background: currentColor;
    /* Match color to text */
    animation: blink 0.7s infinite;
    /* Blinking animation */
}

/* Blinking Animation */
@keyframes blink {
    50% {
        opacity: 0;
    }
}

/* Music Section */
.music-section {
    padding: 50px 20px;
    background-color: #fffcfc;
    color: #000;
}

.music-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.music-text {
    flex: 1;
    margin-right: 20px;
}

.music-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.music-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.iframe-container {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-section {
    background-color: #ffffff;
}

.video-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.block-v {
    padding: 1rem;
    z-index: 3;
}

/* Align title and info blocks */
.title-block-v {
    text-align: left;
    /* Align text to the left */
}

/* Center the video */
.video-block {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Center video vertically if needed */
}

.native-video {
    max-width: 100%;
    height: auto;
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .video-grid-container {
        grid-template-columns: 1fr 1fr;
        /* Two equal columns for larger screens */
    }
}


@keyframes colorChange {
    0% {
        background-color: #ff5f6d;
    }

    25% {
        background-color: #ffc371;
    }

    50% {
        background-color: #47f4ff;
    }

    75% {
        background-color: #42e695;
    }

    100% {
        background-color: #ff5f6d;
    }
}

.dynamic-color-link {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    /* padding: 10px 20px; */
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    background-size: 200% 200%;
    animation: colorChange 8s infinite alternate;
    transition: transform 0.5s ease-out;
}

.dynamic-color-link:hover {
    transform: scale(1.05);
}