
.entry-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    z-index: 1000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow:#1f7a8c;
    width: 400px;
    height: 500px;
    backdrop-filter: blur(10px);
}

.tabs-form {
    display: flex;
    border-bottom:#1f7a8c;
    margin-bottom: 10px;
    font-size: 1.2rem;
    width: 100%;
}

.tab-button {
    flex: 1;
    padding: 10px;
    background: transparent;
    cursor: pointer;
    color: hsla(0, 0%, 13%, 1);
    font-weight: bold;
    transition: color 0.3s ease;
}

.tab-button.active {
    color:#1f7a8c;
}

.tab-button:hover {
    color:#1f7a8c;
}

.form-container {
    padding: 5%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button {
    border: 1px solid#1f7a8c;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    color:#16343a;
    background-color: transparent;
    transition: background-color 0.3s;
}

.close-button:hover {
    background-color:#1f7a8c
}