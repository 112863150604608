
.image-service-section {
    padding: 60px 20px;
    text-align: center;
    background-color: #fff;
    color: #322e2e;
}

.section-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #434040;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.card {
    border-radius: 8px;
    width: 320px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); */
}
.card-wrapper {
    margin-bottom: 20px;
    text-align: center;
}

.image-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 10px;
    color: #333;
}


/* Custom handle style for before-after slider */
.before-after-slider .handle {
    background-color: #202020;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.before-after-slider .caption {
    color: #fff;
    font-weight: 600;
}
.slider-container {
    position: relative;
}
.before-title,
.after-title {
    position: absolute;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}
.before-title {
    top: 10px;
    left: 10px;
}

.after-title {
    top: 10px;
    right: 10px;
}