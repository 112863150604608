/* src/styles/ToolsSection.css */

.tools-section {
    background-color: #071d31;
    color: #fff;
    padding: 50px 20px;
}

.tools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.tool-card {
    background-color: #0a4151;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease;
}

.tool-card:hover {
    transform: translateY(-10px);
}

.tool-card h3 {
    font-size: 1.5rem;
    color: #29e7cd;
}

.tool-card p {
    font-size: 1rem;
    color: #c7d9e6;
}