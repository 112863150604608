
.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    color: #faefefe4;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    /* padding: 10px; */
    border: 1px solid #333;
    border-radius: 8px;
    background-color: #fffcfc;
    display: flex;
    flex-direction: column;
}

.chat-message {
    /* padding: 10px; */
    border-radius: 10px;
    /* margin: 5px 0; */
    max-width: 70%;
    word-break: break-word;
    display: flex;
    flex-direction: column;
}

/* Chat message for the user */
.chat-message.user {
    align-self: flex-end;
    background-color: #d4d0d063;
    color: #141313;
    max-width: 70%;
    width: auto;
    padding: 10px;
    border-radius: 8px;
    margin: 5px 0;
    word-break: break-word;
}

/* Chat message for the AI */
.chat-message.ai {
    align-self: flex-start;
    background-color: #fffbfb;
    color: #050404;
    max-width: 70%;
    width: auto;
    padding: 10px;
    border-radius: 8px;
    margin: 5px 0;
    word-break: break-word;
}

.code-block {
    background-color: #f4f2f2;
    border-radius: 5px;
    /* padding: 10px; */
    white-space: pre-wrap;
    font-family: monospace;
}

.message-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    color: #111010;
}

.action-button {
    color: #100f0f;
    cursor: pointer;
}

.action-button:hover {
    color: #1f7a8c;
}

.chat-input-container {
    display: flex;
    align-items: center;
    padding: 10px;
}

.chat-input {
    flex: 1;
    background-color: #f1f1f1;
    border-radius: 4px;
    color: #211f1f;
    padding: 10px;
    border: none;
}

.send-button {
    margin-left: 10px;
    color: #fff;
    background-color: #1f7a8c;
    cursor: pointer;
    padding: 10px;
}

.send-button:hover {
    background-color: #1f7a8c;
}

.send-button img {
    width: 20px;
    height: 20px;
}

