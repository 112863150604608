.image-service {
    background-color: #fff;
    min-height: 100vh;
    overflow-y: auto;
}

.styled-header {
    background-color: #fff;
    padding: 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.service-tabs {
    margin-bottom: 20px;
}

.service-tab {
    font-weight: bold;
    color: #1c1c1c;
}

.service-tab.Mui-selected {
    color: #1f7a8c;
}

.content-box {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    color: #1c1c1c;
    margin-top: 20px;
}