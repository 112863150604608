.assistant-page {
    padding: 2%;
    max-width: 80%;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.math-form {
    display: flex;
    flex-direction: column;
    border-color: #ccc;
}

.question-input {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: vertical;
    height: 300px;
    width: 100%;
}

.submit-button {
    padding: 10px 15px;
    background-color: #1f7a8c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #185c6a;
}

.response {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.formatted-response p {
    margin: 5px 0;
    line-height: 1.6;
}