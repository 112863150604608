.ai-theme {
    background-color:#ffffff;
    color: #1e1e1e;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
}

.title {
    text-align: center;
    color: #1f7a8c;
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid#1f7a8c;
    border-radius: 10px;
}

.controls {
    margin-top: 10px;
}

.ai-button {
    background-color: #333;
    color:#1f7a8c;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.ai-button:hover {
    background-color: #1f7a8c;
    color: #333;
}

.download-button {
    background-color:#1f7a8c;
    color: white;
}
