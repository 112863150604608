.image-converter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* Ensure the converter takes full width */
}

.image-preview {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.original-image,
.converted-image {
    max-width: 100%;
    /* Responsive images */
    height: auto;
    /* Maintain aspect ratio */
}

.file-input-i {
    margin-bottom: 10px;
}

.file-type-select {
    margin-bottom: 20px;
}

.loading-message {
    margin-top: 10px;
}

.button-container {
    margin-top: 20px;
}

.download-button {
    padding: 10px 20px;
    background-color: #282c34;
    color: white;
    border: none;
    cursor: pointer;
}
.ai-theme {
background-color: #ffffff;
    color: #1e1e1e;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    border-radius: 10px;
    box-shadow: #1f7a8c;
}

.title {
    text-align: center;
    color: #1f7a8c;
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-transform: uppercase;
}