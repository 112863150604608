.text-to-speech-page {
    max-width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content h1 {
    color: #1f7a8c;
    text-align: center;
    margin-bottom: 20px;
}

.form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-control label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

textarea,
select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.generate-button {
    width: 100%;
    padding: 12px;
    background-color: #01adb9;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}
.generate-button-interv {
    width: 30%;
    padding: 12px;
    background-color: #01adb9;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.generate-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.generate-button:hover:not(:disabled) {
    background-color: #017a91;
    color: #ffffff;
}

.audio-player {
    margin-top: 20px;
    text-align: center;
}
.form-itms{
    justify-items: auto;
    
}