/* ImageGenerator.module.css */
.imageGeneratorContainer {
    position: relative;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-top: 30px;
}

.imageGeneratorBox {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    background-color: rgb(255, 254, 254);
    padding: 20px;
    border-radius: 30px;
    z-index: 1;
}

.imageDisplay {
    margin-top: 20px;
    text-align: center;
}

.generatedImage {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.downloadButton {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.promptInput {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #1f7a8c;
    border-radius: 5px;
    margin-bottom: 20px;
}

.generateButton {
    width: 15%;
    padding: 10px;
    font-size: 18px;
    background-color: #1f7a8c;
    color: rgb(213, 213, 213);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 80%;
}

.generateButton:disabled {
    background-color: #1f7a8c;
    cursor: not-allowed;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 254, 254);
    z-index: -1;
    box-shadow: #555050;
}

/* Card.module.css */
.cardContainerg {
display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Creates 3 columns */
    gap: 20px;
    /* Adds space between items */
    padding: 20px;
}

.cardg {
    width: 100%;
    max-width: 500px;
    overflow: hidden;
}

.cardImage {
    width: 100%;
    height: 100%;
    display: block;
}
.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    box-shadow: #323030;
    margin-left: 5%;
    margin-right: 5%;
}